<template>
  <div id="app">

    <div class="spacer"></div>
    <div style="font-size: 240%;">Welcome to MyBurbankBallot.com!</div>
    <div class="spacer"></div>

    <div style="margin:auto;width:90%;font-size: 150%;">
<!--       <span>A voter guide for freedom-loving, common-sense </span>
      <span>individuals who are likely classic liberals or conservatives.</span>
      <span>We made this guide to help you fill out your WHOLE </span>
      <span>ballot and stand your ground as a Burbank Voter.</span> -->
      <span>The best ever bi-partisan common-sense local voter guide favoring public safety</span>
    </div>

<!--     <div class="spacer"></div>
    <div class="infoblock">
      <span>In 2020 and 2022, Burbank saw a sudden shift. A once moderate or more </span>
      <span>conservative area suddenly saw communists and socialists being “elected” </span>
      <span>to city council and school board. 5 candidates manipulated </span>
      <span>ballots to get elected. The people of Burbank do NOT want </span>
      <span>socialists, communists, or pedophile-sympathizers in office. </span>
      <span>If you are sick of far-left extremists plaguing our city, this </span>
      <span>voter guide is for you. Please share with your friends.</span>
      <span>Burbank does not have city or school board elections until November 2024. </span>
      <span>Here are our recommendations for your March 2024 Primary ballot to </span>
      <span>restore sanity to local and state-wide seats, </span>
      <span>in order as it appears on your ballot.</span>
    </div> -->

    <div class="spacer"></div>
    <div class="infoblock">
      <div>
        <span>US House District 30: </span>
        <span class="textbold">Alex Balekian</span>
      </div>
      <div>
        <span>California State Assembly District 44: </span>
        <span class="textbold">Tony Rodriguez</span>
      </div>
      <div>
        <span>Los Angeles District Attorney: </span>
        <span class="textbold">Nathan Hochman</span>
      </div>
      <div>
        <span>Water Replenishment District, Division 2: </span>
        <span class="textbold">Liz Peterson</span>
      </div>
      <div>
        <span>US Senate: </span>
        <span class="textbold">Steve Garvey</span>
      </div>
    </div>

    <div class="spacer"></div>
    <div class="infoblock">
      <div>
        <span>Burbank City Council (vote For TWO): </span>
        <span class="textbold">Chris Rizzotti</span>
        <span> and </span>
        <span class="textbold">Judie Wilke</span>
      </div>
    </div>

    <div class="spacer"></div>
    <div class="infoblock">
      <div>Burbank Unified School District (now by trustee area)</div>
      <div>
        <span>Area 1: </span>
        <span class="textbold">Tom Crowther</span>
      </div>
      <div>
        <span>Area 5: </span>
        <span class="textbold">Annie Markarian</span>
      </div>
      <div>
        <span>Measure ABC: </span>
        <span class="textbold">NO (oppose)</span>
      </div>
    </div>

    <div class="spacer"></div>
    <div class="infoblock">
      <div style="font-weight: 500;">Statewide Propositions</div>
      <div>
        <span>Vote NO (oppose) on ALL except Proposition </span>
        <span class="textbold">36 </span>
        <span>Vote </span>
        <span class="textbold">YES</span>
      </div>
    </div>

    <div class="spacer"></div>
    <div class="infoblock">
      <div>Judges</div>
      <div>
        <span>Ratings for Candidates and Judges in Los Angeles County: <a href="https://www.electionforum.org">www.electionforum.org</a></span>
      </div>
      <div>
        <span>Ratings for Judges throughout the United States: <a href="https://www.judgevoterguide.com">www.judgevoterguide.com</a></span>
      </div>
    </div>

  <div style="height:60px;"></div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      info: '',
    };
  },
  watch: {
    tbd(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
  },
  methods: {
    tbdfunc() {
      console.log('tbd');
    },
  },
  // beforeDestroy() {

  // },
  // mounted() {

  // },
};

</script>

<style>

#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.main-font {
  font-size: 100px;
}

.info-font {
  font-size: 48px;
}

.textshadow{
  text-shadow: 1px 1px 1px black;
}

.debug{
/*  border: 1px solid red;*/
}

.spacer{
  height:20px;
}

.infoblock{
  margin:auto;
  width:80%;
  font-size: 120%;
}

.textbold {
  font-weight: 800;
}

@media (max-width: 1600px) { /* For mobile devices */
  .main-font {
    font-size: 80px;
  }
  .info-font {
    font-size: 42px;
  }
  .goal-width {
    width:56%;
  }
}

@media (max-width: 1400px) { /* For mobile devices */
  .main-font {
    font-size: 60px;
  }
  .info-font {
    font-size: 36px;
  }
  .goal-width {
    width:60%;
  }
  .endorsewidth {
    width:190px;
  }
  .endorsefont {
    font-size: 120%;
  }
}

@media (max-width: 1300px) { /* For mobile devices */
  .main-font {
    font-size: 50px;
  }
  .info-font {
    font-size: 30px;
  }
  .endorsewidth {
    width:180px;
  }
  .endorsefont {
    font-size: 100%;
  }
}

@media (max-width: 1000px) { /* For mobile devices */
  .main-font {
    font-size: 46px;
  }
  .info-font {
    font-size: 20px;
  }
  .goal-width {
    width:80%;
  }
  .endorsewidth {
    width:120px;
  }
  .endorsefont {
    font-size: 90%;
  }
  .endorse-height {
    height:160px;
  }
}

@media (max-width: 768px) { /* For mobile devices */
  .main-font {
    font-size: 30px;
  }
  .info-font {
    font-size: 20px;
  }
  .endorsewidth {
    width:90px;
  }
  .endorsefont {
    font-size: 84%;
  }
  .endorse-height {
    height:120px;
  }
}

@media (max-width: 460px) { /* For mobile devices */
  .main-font {
    font-size: 24px;
  }
  .info-font {
    font-size: 20px;
  }
  .endorsewidth {
    width:60px;
  }
  .endorsefont {
    font-size: 80%;
  }
  .endorse-height {
    height:80px;
  }
}

@media (max-width: 412px) { /* For mobile devices */
  .main-font {
    font-size: 22px;
  }
  .info-font {
    font-size: 18px;
  }
  .endorsewidth {
    width:50px;
  }
  .endorsefont {
    font-size: 76%;
  }
}

@media (max-width: 320px) { /* For mobile devices */
  .main-font {
    font-size: 18px;
  }
  .info-font {
    font-size: 16px;
  }
  .endorsewidth {
    width:40px;
  }
  .endorsefont {
    font-size: 40%;
  }
}

</style>
